<template>
  <v-app id="inspire">
    
      <v-navigation-drawer v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app class="d-print-none print-push-left">
      <v-list dense v-if="data">
        <template>
          <router-link to="/home" class="sidebar-link">
           <v-list-item link style="margin: 5px 0px 5px 0px;">
            <v-list-item-icon>
                <v-icon size="34" class="instagram">mdi-home</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
           </router-link>
         <v-divider color="#90A4AE" style="margin:0px"></v-divider>
           <div v-for="link in links"
            :key="link.linkname">
          <router-link :to="{ name:link.linkname}" class="sidebar-link">
            
          <v-list-item link style="margin: 5px 0px 5px 0px;">
          <v-list-item-icon style="">
            <v-icon size="30" class="instagram">{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title style="font-size: 15px;">{{ link.link_display_name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </router-link>
         <v-divider style="margin:0px"></v-divider>
      </div>
            
        </template>
      </v-list>
    </v-navigation-drawer>

    <Header :drawer="$store.state.drawer"></Header>
  
    <v-content>
      <v-container fluid> 
             <router-view></router-view> 
      </v-container>
      <!-- <div>Quick-Links RD
        <router-link :to="{name:'employee-type'}">click</router-link>
      </div> -->
    </v-content>
     <Footer :drawer="$store.state.drawer"></Footer>
  </v-app>
</template>

<script>
import axios from 'axios'
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
export default {
  //props: ['para_role_type'],
  props: {
    role:String,
  },
  data() {
      return {
          // rt:this.$route.query.roletype
          data:{},
          parentUrl:'',
          links:null,
          rolename:null
          }
  },
  components: {
     Header:Header,
     Footer:Footer,
  },
  methods:{
    back(){
      this.$router.push('/home');
    }
  },
  watch:{
    
  },
  mounted(){
    this.rolename = this.$route.params.role

    const paradata={
      role:this.rolename,
    }
    axios.post("/home/getlinks",paradata)
    .then(res =>{
      //window.console.log("res"+res.data)
      if(res.data.msg=="200"){
          console.log(res.data)
          this.links = res.data.links
          this.parentUrl = this.$route.path
      }
      else{
        console.log('empty')
      }
    })
    .catch(error => {
        window.console.log(error)
    })
  } 
}
</script>


<style scoped>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
@media print {
  .print-push-left{
    margin-left: -256px;
    display: none !important;
  }
}
/* .instagram {
        text-transform: uppercase;
      background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  } */
  .instagram {
    background: -webkit-radial-gradient(33% 100% circle, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    background: radial-gradient(circle at 33% 100%, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>